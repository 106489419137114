/* eslint-disable import/no-extraneous-dependencies */
import { FormLabelAlign } from 'antd/lib/form/interface';
import { useField } from 'formik';
import { Rule } from 'rc-field-form/lib/interface';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { FormItem } from 'formik-antd';
import { isUndefinedOrNullOrEmpty } from 'common';
import { useTranslation } from 'react-i18next';

export interface CurrencyNumberInputProp {
  value?: string | number;
  id: string;
  name: string;
  className?: string;
  label: string;
  extra?: string;
  placeholder?: string;
  prefix?: string;
  suffix?: string;
  labelAlign?: FormLabelAlign; // default: right
  labelCol?: object; // ant design Col object
  wrapperCol?: object; // ant design Col object
  tooltip?: ReactNode;
  rules?: Rule[];
  noStyle?: boolean;
  required?: boolean;
  minLimit?: number;
  maxLimit?: number;
  disableAbbreviations?: boolean;
  disableGroupSeparators?: boolean;
  allowDecimal?: boolean;
  allowNegativeValue?: boolean;
  decimalScale?: number;
  decimalsLimit?: number;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  handleChangeInput?: (name: string, value: number | string, formatted: string, errorMessages?: string) => void;
}

export const CurrencyNumberInput: React.FC<CurrencyNumberInputProp> = ({
  className,
  value,
  name,
  minLimit,
  prefix,
  suffix,
  maxLimit,
  handleChangeInput,
  label,
  extra,
  tooltip,
  labelCol,
  wrapperCol,
  labelAlign,
  required,
  rules,
  noStyle,
  id,
  placeholder,
  disableAbbreviations,
  disableGroupSeparators,
  allowNegativeValue,
  allowDecimal,
  decimalScale,
  decimalsLimit,
  disabled,
  readOnly,
  maxLength,
}) => {
  const { t } = useTranslation();
  const [field, meta, helper] = useField({ name });
  const [errorMessages, setErrorMessage] = useState('');
  const [classNameState, setClassNameState] = useState('');

  const handleOnValueChange = (
    valueData?: string | number,
    nameVal?: string,
    values?: {
      float: number | null;
      formatted: string | null;
      value: string | null;
    }
  ): void => {
    let errorFlag = false;

    if (Number.isNaN(Number(valueData))) {
      setErrorMessage(t('validate.requiredValidNumber'));
      errorFlag = true;
    }

    if (minLimit !== undefined && minLimit > Number(valueData)) {
      setErrorMessage(`value must at least ${prefix ?? ''}${minLimit}${suffix ?? ''}`);
      errorFlag = true;
    }

    if (maxLimit !== undefined && Number(valueData) > maxLimit) {
      setErrorMessage(`value must at less than or equal to ${prefix ?? ''}${maxLimit}${suffix ?? ''}`);
      // eslint-disable-next-line no-param-reassign
      valueData = maxLimit;
      errorFlag = true;
    }

    if (errorFlag) {
      setClassNameState('ant-form-item-has-error');
    } else {
      setClassNameState('');
      setErrorMessage('');
    }

    helper.setValue(valueData);

    if (handleChangeInput && nameVal != null) {
      handleChangeInput(nameVal, valueData ?? '', values?.formatted ?? '', errorMessages);
    }
  };

  const handleOnBlur = (event: any): void => {
    if (!isUndefinedOrNullOrEmpty(event.value) && !Number.isNaN(event.value)) {
      helper.setValue(Number(event.value) || undefined);
    }
  };

  const displayValidateStatus = useMemo(() => {
    if (meta.touched && (meta.error || errorMessages)) return 'error';

    return '';
  }, [errorMessages, meta.error, meta.touched]);

  useEffect(() => {
    if (errorMessages) {
      helper.setTouched(true, true);
      helper.setError(errorMessages);
    }
  }, [errorMessages, helper]);

  const invalidErrorInputClassName = useMemo(() => {
    if (meta.touched && (meta.error || errorMessages)) {
      return 'ant-input-affix-wrapper-status-error';
    }
    return '';
  }, [errorMessages, meta.error, meta.touched]);

  return (
    <FormItem
      label={label}
      name={field.name}
      className={`${className} ${classNameState}`}
      tooltip={tooltip}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      help={meta.touched && (meta.error || errorMessages) ? errorMessages || t(`${meta.error}`) : undefined}
      labelAlign={labelAlign}
      required={required}
      rules={rules}
      noStyle={noStyle}
      validateStatus={displayValidateStatus}
      extra={extra}
    >
      <CurrencyInput
        id={id}
        name={name}
        className={`ant-input-affix-wrapper ${invalidErrorInputClassName}`}
        value={field.value || value}
        onValueChange={handleOnValueChange}
        onBlur={handleOnBlur}
        placeholder={placeholder}
        prefix={prefix}
        suffix={suffix}
        disableAbbreviations={disableAbbreviations}
        disableGroupSeparators={disableGroupSeparators}
        allowNegativeValue={allowNegativeValue}
        allowDecimals={allowDecimal}
        step={1}
        maxLength={maxLength}
        decimalScale={decimalScale}
        decimalsLimit={decimalsLimit}
        disabled={disabled}
        readOnly={readOnly}
      />
    </FormItem>
  );
};

CurrencyNumberInput.defaultProps = {
  labelAlign: 'left',
};
